// Statuses
export const DESTINATION_STATUS_UNAUTHENTICATED = 1;
export const DESTINATION_STATUS_AUTHENTICATED = 2;
export const DESTINATION_STATUS_CONFIGURED = 3;
export const DESTINATION_STATUS_WARNING = 4;
export const DESTINATION_STATUS_ERROR = 5;
export const DESTINATION_STATUS_AUTH_ERROR = 6;
export const DESTINATION_STATUS_FOR_ENTITLEMENTS = [
  DESTINATION_STATUS_CONFIGURED,
  DESTINATION_STATUS_ERROR,
  DESTINATION_STATUS_AUTH_ERROR,
];

// Limits
export const TWITTER_LIMIT = 280;
export const TWITTER_LINK_LENGTH = 23; // t.co links are 23 characters long
export const INSTAGRAM_HASHTAGS_LIMIT = 30;
export const FACEBOOK_HASHTAGS_LIMIT = 30;

// Channels
export const CAMPAIGNS = 9999;
export const CHANNEL_API = 1;
export const CHANNEL_WORDPRESS = 2;
export const CHANNEL_DRUPAL = 3;
export const CHANNEL_WILLIAM = 4;
export const CHANNEL_FACEBOOK = 5;
export const CHANNEL_TWITTER = 6;
export const CHANNEL_MEDIUM = 7;
export const CHANNEL_MAILCHIMP = 8;
// const CHANNEL_INSTANT_ARTICLE= 9;
export const CHANNEL_LINKEDIN = 10;
export const CHANNEL_FORK = 11;
export const CHANNEL_BLOGGER = 12;
export const CHANNEL_AMP = 13;
export const CHANNEL_SHOPIFY = 14;
export const CHANNEL_RSS = 15;
export const CHANNEL_CRAFT = 16;
export const CHANNEL_SITEMANAGER = 17;
export const CHANNEL_AMBASSADORS = 18;
export const CHANNEL_HUBSPOT = 19;
export const CHANNEL_PUPPETEER = 20;
export const CHANNEL_CAMPAIGN_MONITOR = 21;
export const CHANNEL_ACTIVE_CAMPAIGN = 22;
export const CHANNEL_PRESS = 23;
export const CHANNEL_PINTEREST = 25;
export const CHANNEL_WEBFLOW = 26;
export const CHANNEL_COPY_PASTE = 27;
export const CHANNEL_JOOMLA = 28;
export const CHANNEL_GOOGLE_BUSINESS = 29;
export const CHANNEL_INSTAGRAM = 30;
export const CHANNEL_SLACK = 31;
export const CHANNEL_LINKEDIN_PROFILE = 32;
export const CHANNEL_STATAMIC = 33;
export const CHANNEL_GHOST = 35;
export const CHANNEL_TIKTOK = 36;
export const CHANNEL_DEV_TO = 37;
export const CHANNEL_SHAREPOINT_ONLINE = 38;
export const CHANNEL_WIX = 39;
export const CHANNEL_CONTENTFUL = 40;
export const CHANNEL_STRAPI = 41;
export const CHANNEL_THREADS = 43;
export const CHANNEL_GROUP_PERSONAL = 1;
export const CHANNEL_GROUP_EXTERNAL = 2;
export const CHANNEL_GROUP_SHARING = 3;
export const CHANNEL_GROUP_REFERRAL = 4;
export const CHANNEL_GROUP_EMAIL = 5;
export const CHANNEL_GROUP_MOBILE = 6;
export const PERSONAL_CHANNELS = [
  CHANNEL_API,
  CHANNEL_WORDPRESS,
  CHANNEL_DRUPAL,
  CHANNEL_WEBFLOW,
  CHANNEL_WILLIAM,
  CHANNEL_HUBSPOT,
  CHANNEL_SHOPIFY,
  CHANNEL_PUPPETEER,
  CHANNEL_STATAMIC,
  CHANNEL_CRAFT,
  CHANNEL_JOOMLA,
  CHANNEL_GHOST,
  CHANNEL_SITEMANAGER,
  CHANNEL_FORK,
  CHANNEL_BLOGGER,
  CHANNEL_COPY_PASTE,
  CHANNEL_SHAREPOINT_ONLINE,
  CHANNEL_CONTENTFUL,
  CHANNEL_WIX,
  CHANNEL_STRAPI,
];
export const EXTERNAL_CHANNELS = [CHANNEL_MEDIUM, CHANNEL_RSS, CHANNEL_DEV_TO];
export const SHARING_CHANNELS = [
  CHANNEL_FACEBOOK,
  CHANNEL_TWITTER,
  CHANNEL_INSTAGRAM,
  CHANNEL_LINKEDIN,
  CHANNEL_LINKEDIN_PROFILE,
  CHANNEL_PINTEREST,
  CHANNEL_TIKTOK,
  CHANNEL_GOOGLE_BUSINESS,
  CHANNEL_SLACK,
  CHANNEL_THREADS,
];
export const EMAIL_CHANNELS = [
  CHANNEL_MAILCHIMP,
  CHANNEL_CAMPAIGN_MONITOR,
  CHANNEL_ACTIVE_CAMPAIGN,
];
export const MOBILE_CHANNELS = [CHANNEL_AMP];
export const REFERRAL_CHANNELS = [CHANNEL_AMBASSADORS, CHANNEL_PRESS];
export const METRICS_CHANNELS = [
  CHANNEL_FACEBOOK,
  CHANNEL_LINKEDIN,
  CHANNEL_PINTEREST,
  CHANNEL_INSTAGRAM,
  CHANNEL_GOOGLE_BUSINESS,
  CHANNEL_THREADS,
];
export const CHANNELS_WITHOUT_STATS = [
  CHANNEL_MEDIUM,
  CHANNEL_RSS,
  CHANNEL_SLACK,
  CHANNEL_DEV_TO,
  CHANNEL_TWITTER,
  CHANNEL_GOOGLE_BUSINESS,
];
export const CHANNELS_WITH_STORY_CUSTOMIZATION = [
  CHANNEL_FACEBOOK,
  CHANNEL_TWITTER,
  CHANNEL_LINKEDIN,
  CHANNEL_PINTEREST,
  CHANNEL_GOOGLE_BUSINESS,
  CHANNEL_INSTAGRAM,
  CHANNEL_SLACK,
  CHANNEL_LINKEDIN_PROFILE,
  CHANNEL_THREADS,
];
export const CHANNELS_WITH_LINK_CUSTOMIZATION = [
  CHANNEL_FACEBOOK,
  CHANNEL_LINKEDIN,
  CHANNEL_PINTEREST,
  CHANNEL_GOOGLE_BUSINESS,
  CHANNEL_INSTAGRAM,
  CHANNEL_SLACK,
  CHANNEL_LINKEDIN_PROFILE,
];
export const CHANNEL_NAMES = {
  [CHANNEL_API]: 'Api',
  [CHANNEL_WORDPRESS]: 'WordPress',
  [CHANNEL_DRUPAL]: 'Drupal',
  [CHANNEL_WILLIAM]: 'Blog',
  [CHANNEL_FACEBOOK]: 'Facebook Page',
  [CHANNEL_TWITTER]: 'Twitter',
  [CHANNEL_MEDIUM]: 'Medium',
  [CHANNEL_MAILCHIMP]: 'Mailchimp',
  [CHANNEL_LINKEDIN]: 'LinkedIn Page',
  [CHANNEL_FORK]: 'Fork',
  [CHANNEL_BLOGGER]: 'Blogger',
  [CHANNEL_AMP]: 'Google amp',
  [CHANNEL_SHOPIFY]: 'Shopify',
  [CHANNEL_RSS]: 'RSS',
  [CHANNEL_CRAFT]: 'Craft',
  [CHANNEL_SITEMANAGER]: 'SiteManager',
  [CHANNEL_AMBASSADORS]: 'Ambassadors',
  [CHANNEL_HUBSPOT]: 'Hubspot',
  [CHANNEL_PUPPETEER]: 'Api',
  [CHANNEL_CAMPAIGN_MONITOR]: 'Campaign Monitor',
  [CHANNEL_ACTIVE_CAMPAIGN]: 'Active Campaign',
  [CHANNEL_PRESS]: 'Press',
  [CHANNEL_PINTEREST]: 'Pinterest',
  [CHANNEL_WEBFLOW]: 'Webflow',
  [CHANNEL_COPY_PASTE]: 'Clipboard',
  [CHANNEL_JOOMLA]: 'Joomla!',
  [CHANNEL_GOOGLE_BUSINESS]: 'Google My Business',
  [CHANNEL_INSTAGRAM]: 'Instagram',
  [CHANNEL_SLACK]: 'Slack',
  [CHANNEL_LINKEDIN_PROFILE]: 'LinkedIn Profile',
  [CHANNEL_STATAMIC]: 'Statamic',
  [CHANNEL_GHOST]: 'Ghost',
  [CHANNEL_TIKTOK]: 'TikTok',
  [CHANNEL_DEV_TO]: 'DEV',
  [CHANNEL_SHAREPOINT_ONLINE]: 'SharePoint Online',
  [CHANNEL_CONTENTFUL]: 'Contentful',
  [CHANNEL_WIX]: 'Wix Blog',
  [CHANNEL_STRAPI]: 'Strapi',
  [CHANNEL_THREADS]: 'Threads',
};
export const CHANNEL_ROUTES = {
  [CHANNEL_API]: 'channels/api',
  [CHANNEL_WORDPRESS]: 'channels/wordpress',
  [CHANNEL_DRUPAL]: 'channels/drupal',
  [CHANNEL_WILLIAM]: 'channels/story-chief',
  [CHANNEL_FACEBOOK]: 'channels/facebook',
  [CHANNEL_TWITTER]: 'channels/twitter',
  [CHANNEL_MEDIUM]: 'channels/medium',
  [CHANNEL_MAILCHIMP]: 'channels/mailchimp',
  [CHANNEL_LINKEDIN]: 'channels/linkedin',
  [CHANNEL_FORK]: 'channels/fork',
  [CHANNEL_BLOGGER]: 'channels/blogger',
  [CHANNEL_AMP]: 'channels/google-amp',
  [CHANNEL_SHOPIFY]: 'channels/shopify',
  [CHANNEL_RSS]: 'channels/rss',
  [CHANNEL_CRAFT]: 'channels/craft',
  [CHANNEL_SITEMANAGER]: 'channels/sitemanager',
  [CHANNEL_AMBASSADORS]: 'channels/ambassadors',
  [CHANNEL_HUBSPOT]: 'channels/hubspot',
  [CHANNEL_PUPPETEER]: 'channels/api',
  [CHANNEL_CAMPAIGN_MONITOR]: 'channels/campaign-monitor',
  [CHANNEL_ACTIVE_CAMPAIGN]: 'channels/active-campaign',
  [CHANNEL_PRESS]: 'channels/press',
  [CHANNEL_PINTEREST]: 'channels/pinterest',
  [CHANNEL_WEBFLOW]: 'channels/webflow',
  [CHANNEL_COPY_PASTE]: 'channels/clipboard',
  [CHANNEL_JOOMLA]: 'channels/joomla',
  [CHANNEL_GOOGLE_BUSINESS]: 'channels/google-business',
  [CHANNEL_INSTAGRAM]: 'channels/instagram',
  [CHANNEL_SLACK]: 'channels/slack',
  [CHANNEL_LINKEDIN_PROFILE]: 'channels/linkedin-profile',
  [CHANNEL_STATAMIC]: 'channels/statamic',
  [CHANNEL_GHOST]: 'channels/ghost',
  [CHANNEL_TIKTOK]: 'channels/tiktok',
  [CHANNEL_DEV_TO]: 'channels/dev-to',
  [CHANNEL_SHAREPOINT_ONLINE]: 'channels/sharepoint-online',
  [CHANNEL_CONTENTFUL]: 'channels/contentful',
  [CHANNEL_WIX]: 'channels/wix',
  [CHANNEL_STRAPI]: 'channels/strapi',
  [CHANNEL_THREADS]: 'channels/threads',
};
export const CHANNEL_SLUGS = {
  [CHANNEL_API]: 'api',
  [CHANNEL_WORDPRESS]: 'wordpress',
  [CHANNEL_DRUPAL]: 'drupal',
  [CHANNEL_WILLIAM]: 'story-chief',
  [CHANNEL_FACEBOOK]: 'facebook',
  [CHANNEL_TWITTER]: 'twitter',
  [CHANNEL_MEDIUM]: 'medium',
  [CHANNEL_MAILCHIMP]: 'mailchimp',
  [CHANNEL_LINKEDIN]: 'linkedin',
  [CHANNEL_FORK]: 'fork',
  [CHANNEL_BLOGGER]: 'blogger',
  [CHANNEL_AMP]: 'google-amp',
  [CHANNEL_SHOPIFY]: 'shopify',
  [CHANNEL_RSS]: 'rss',
  [CHANNEL_CRAFT]: 'craft',
  [CHANNEL_SITEMANAGER]: 'sitemanager',
  [CHANNEL_AMBASSADORS]: 'ambassadors',
  [CHANNEL_HUBSPOT]: 'hubspot',
  [CHANNEL_PUPPETEER]: 'api',
  [CHANNEL_CAMPAIGN_MONITOR]: 'campaign-monitor',
  [CHANNEL_ACTIVE_CAMPAIGN]: 'active-campaign',
  [CHANNEL_PRESS]: 'press',
  [CHANNEL_PINTEREST]: 'pinterest',
  [CHANNEL_WEBFLOW]: 'webflow',
  [CHANNEL_COPY_PASTE]: 'clipboard',
  [CHANNEL_JOOMLA]: 'joomla',
  [CHANNEL_GOOGLE_BUSINESS]: 'google-business',
  [CHANNEL_INSTAGRAM]: 'instagram',
  [CHANNEL_SLACK]: 'slack',
  [CHANNEL_LINKEDIN_PROFILE]: 'linkedin-profile',
  [CHANNEL_STATAMIC]: 'statamic',
  [CHANNEL_GHOST]: 'ghost',
  [CHANNEL_TIKTOK]: 'tiktok',
  [CHANNEL_DEV_TO]: 'dev-to',
  [CHANNEL_SHAREPOINT_ONLINE]: 'sharepoint-online',
  [CHANNEL_CONTENTFUL]: 'contentful',
  [CHANNEL_WIX]: 'wix',
  [CHANNEL_STRAPI]: 'strapi',
  [CHANNEL_THREADS]: 'threads',
};
export const SPRITES_CHANNELS = {
  [CHANNEL_API]: 'api',
  [CHANNEL_WORDPRESS]: 'wordpress',
  [CHANNEL_DRUPAL]: 'drupal',
  [CHANNEL_WILLIAM]: 'story_chief',
  [CHANNEL_FACEBOOK]: 'facebook',
  [CHANNEL_TWITTER]: 'twitter',
  [CHANNEL_MEDIUM]: 'medium',
  [CHANNEL_MAILCHIMP]: 'mailchimp',
  [CHANNEL_LINKEDIN]: 'linkedin',
  [CHANNEL_FORK]: 'fork',
  [CHANNEL_BLOGGER]: 'blogger',
  [CHANNEL_AMP]: 'google-amp',
  [CHANNEL_SHOPIFY]: 'shopify',
  [CHANNEL_RSS]: 'rss',
  [CHANNEL_CRAFT]: 'craft',
  [CHANNEL_SITEMANAGER]: 'sitemanager',
  [CHANNEL_AMBASSADORS]: 'ambassadors',
  [CHANNEL_HUBSPOT]: 'hubspot',
  [CHANNEL_PUPPETEER]: 'api',
  [CHANNEL_CAMPAIGN_MONITOR]: 'campaign-monitor',
  [CHANNEL_ACTIVE_CAMPAIGN]: 'active-campaign',
  [CHANNEL_PRESS]: 'press',
  [CHANNEL_PINTEREST]: 'pinterest',
  [CHANNEL_WEBFLOW]: 'webflow',
  [CHANNEL_COPY_PASTE]: 'clipboard',
  [CHANNEL_JOOMLA]: 'joomla',
  [CHANNEL_GOOGLE_BUSINESS]: 'google-business',
  [CHANNEL_INSTAGRAM]: 'instagram',
  [CHANNEL_SLACK]: 'slack',
  [CHANNEL_LINKEDIN_PROFILE]: 'linkedin-profile',
  [CHANNEL_STATAMIC]: 'statamic',
  [CHANNEL_GHOST]: 'ghost',
  [CHANNEL_TIKTOK]: 'tiktok',
  [CHANNEL_DEV_TO]: 'dev-to',
  [CHANNEL_SHAREPOINT_ONLINE]: 'sharepoint-online',
  [CHANNEL_CONTENTFUL]: 'contentful',
  [CHANNEL_WIX]: 'wix',
  [CHANNEL_STRAPI]: 'strapi',
  [CHANNEL_THREADS]: 'threads',
};
