import { uniqBy } from 'lodash-es';
import { JSX } from 'react';
import StoryChief from '@/storychief';
import { Entitlement } from '@/types/entitlement';

const LABELS = {
  socials: {
    label: null,
  },
  'monthly-published-posts-count': {
    label: 'Social posts',
  },
  'channels-socials-count': {
    label: 'Social channels',
  },
  calendar: {
    label: 'Content Calendar',
  },
  'insights-socials': {
    label: 'Post Analytics',
  },
  'indefinite-scheduling-socials': {
    label: 'Indefinite scheduling',
  },
  'channel-insights-socials': {
    label: 'Channel Analytics',
  },
  'datatable-filtering-socials': {
    label: 'Advanced filtering',
  },
  'datatable-segments-socials': {
    label: 'Filter segments',
  },
  'custom-fields-socials': {
    label: 'Custom fields',
  },
  'public-api-socials': {
    label: 'Rest API',
  },
  'audience-restrictions': {
    label: 'Audience restrictions',
  },
  'customize-link-previews': {
    label: 'Custom link previews',
  },
  campaigns: {
    label: null,
  },
  'insights-campaigns': {
    label: 'Campaign Analytics',
  },
  'custom-fields-campaigns': {
    label: 'Custom fields',
  },
  'datatable-filtering-campaigns': {
    label: 'Advanced filtering',
  },
  'datatable-segments-campaigns': {
    label: 'Filter segments',
  },
  stories: {
    label: null,
  },
  'monthly-published-stories-count': {
    label: 'Articles',
  },
  'channels-stories-count': {
    label: 'Websites',
  },
  'insights-stories': {
    label: 'Article Analytics',
  },
  'indefinite-scheduling-stories': {
    label: 'Indefinite scheduling',
  },
  'multi-language': {
    label: 'Multi-language support',
  },
  'datatable-filtering-stories': {
    label: 'Advanced filtering',
  },
  'datatable-segments-stories': {
    label: 'Filter segments',
  },
  'custom-fields-stories': {
    label: 'Custom fields',
  },
  'public-api-stories': {
    label: 'Rest API',
  },
  comments: {
    label: 'Comments',
  },
  'user-seat-count': {
    label: 'Users',
  },
  'chat-support': {
    label: 'Chat support',
  },
  approval: {
    label: 'Content approval',
  },
  'batch-approval': {
    label: 'Batch sharing for approval',
  },
  'version-history': {
    label: 'Content version history',
  },
  'guests-unlimited': {
    label: 'Unlimited guests',
  },
  'public-links': {
    label: 'Public links',
  },
  referrals: {
    label: 'Referrals',
  },
  'ai-assistant': {
    label: null,
  },
  'ai-assistant-monthly-word-count': {
    label: 'Monthly AI generated words',
  },
  'content-types': {
    label: 'Content types',
  },
  'datatable-filtering-content-types': {
    label: 'Advanced filtering',
  },
  'datatable-segments-content-types': {
    label: 'Filter segments',
  },
  'custom-fields-content-types': {
    label: 'Custom fields',
  },
  'channel-insights-gsc': {
    label: 'SEO Performance',
  },
  'recommendations-board': {
    label: 'Content audit',
  },
};

function getValueLabel(value: Entitlement) {
  if (typeof value === 'number') {
    return value === -1 ? 'Unlimited' : value;
  }

  return (
    <div>
      <img src={StoryChief.asset('images/components/features/feature-check.svg')} alt="" />
      <span className="sr-only">Yes</span>
    </div>
  );
}

type PricingTableType = {
  entitlements: Entitlement[];
};

function PricingTable({ entitlements }: PricingTableType): JSX.Element {
  return (
    <table className="sc-pricing-table">
      <tbody>
        {uniqBy(entitlements, ({ key }) => key).map(({ key, value }: Entitlement) => {
          const label = LABELS[key]?.label;

          if (!label) {
            return null;
          }

          return (
            <tr key={key} className="sc-pricing-table__tr">
              <td className="sc-pricing-table__td sc-pricing-table__td--label">{label}</td>
              <td className="sc-pricing-table__td sc-pricing-table__td--value">
                {getValueLabel(value)}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default PricingTable;
