import { JSX, useEffect } from 'react';
import classNames from 'classnames';
import { Icon } from '@iconify-icon/react';
import useEntitlements from '@/entitlements/hooks/useEntitlements';
import StoryChief from '@/storychief';
import PricingCard from '@/pricing/components/PricingCard';
import PricingCardPlaceholder from '@/pricing/components/PricingCardPlaceholder';
import useUpgradePathForEntitlement from '@/pricing/hooks/useUpgradePathForEntitlement';
import analyticsTrack from '@/storychief/utils/analyticsTrack';
import { Price } from '@/types/price';
import { Entitlement } from '@/types/entitlement';

const ENTITLEMENTS_DATA = {
  campaigns: {
    title: 'Content Campaigns',
    image: 'campaigns.png',
    icon: 'campaigns.svg',
    iconSize: 25,
    description:
      'Monitor the performance of multiple pieces of content from the same campaign. Collaborate, set KPIs, and assign content briefs.',
    testId: 'entitlement-content-campaigns-teaser-title',
  },
  'task-management': {
    title: 'Task management',
    image: 'campaigns.png',
    icon: 'campaigns.svg',
    iconSize: 25,
    description: 'Organize and prioritize team tasks for seamless project management.',
    testId: 'entitlement-task-management-teaser-title',
  },
  calendar: {
    title: 'Content Calendar',
    image: 'calendar.png',
    icon: 'calendar.svg',
    description:
      'From ideation to publication, get a clear overview of your content and social media planning.',
    testId: 'entitlement-content-calendar-teaser-title',
  },
  'channel-insights-socials': {
    title: 'Channel Insights Socials',
    image: 'channel-insights-socials.png',
    icon: 'chart-trend.svg',
    description:
      'From ideation to publication, get a clear overview of your content and social media planning.',
    testId: 'entitlement-content-channel-insights-socials-teaser-title',
  },
  'channel-insights-gsc': {
    title: 'SEO performance',
    image: 'channel-insights-gsc.png',
    icon: 'chart-trend.svg',
    description: 'Interpret your SEO performance with ease and enhance your content strategies.',
    testId: 'entitlement-content-channel-insights-gsc-teaser-title',
  },
  'insights-stories': {
    title: 'Article Analytics & Reports',
    image: 'insights-stories.png',
    icon: 'chart-trend.svg',
    description:
      'Create articles that resonate with your audience by having the right reports in place.',
  },
  socials: {
    title: 'Social Posts',
    image: 'socials.png',
    icon: 'socials.svg',
    description:
      'Save time managing your social media in one place. Publish to all social media channels with one click and track engagement.',
    testId: 'entitlement-content-socials-teaser-title',
  },
  'datatable-filtering-socials': {
    title: 'Advanced filtering',
    image: 'datatable-filtering.png',
    description: 'Quickly organize and identify key content by filtering on any criteria.',
  },
  'datatable-filtering-campaigns': {
    title: 'Advanced filtering',
    image: 'datatable-filtering.png',
    description: 'Quickly organize and identify key content by filtering on any criteria.',
  },
  'datatable-filtering-stories': {
    title: 'Advanced filtering',
    image: 'datatable-filtering.png',
    description: 'Quickly organize and identify key content by filtering on any criteria.',
  },
  'datatable-filtering-content-types': {
    title: 'Advanced filtering',
    image: 'datatable-filtering.png',
    description: 'Quickly organize and identify key content by filtering on any criteria.',
  },
  'batch-approval': {
    title: 'Collaborate on content with your team',
    image: 'batch-approval.png',
    description:
      "Improve your team's workflow by having the ability to review multiple items at once.",
  },
  comments: {
    title: 'Streamlined feedback and approvals',
    image: 'comments.png',
    description: 'Streamline collaboration and approvals of your copy and content calendar.',
  },
  'audience-restrictions': {
    title: 'Audience restriction',
    description:
      'Target your post to ensure you reach the right audience and create content that is highly relevant.',
  },
  'custom-fields-socials': {
    title: 'Custom fields',
    image: 'custom-fields-socials.png',
    description: 'Streamline and improve your internal workflow by adding any additional details.',
  },
  'custom-fields-stories': {
    title: 'Custom fields',
    image: 'custom-fields-socials.png',
    description: 'Streamline and improve your internal workflow by adding any additional details.',
  },
  'custom-fields-content-types': {
    title: 'Custom fields',
    image: 'custom-fields-socials.png',
    description: 'Streamline and improve your internal workflow by adding any additional details.',
  },
  'custom-fields': {
    title: 'Custom fields',
    image: 'custom-fields-socials.png',
    description: 'Streamline and improve your internal workflow by adding any additional details.',
  },
  'customize-link-previews': {
    title: 'Modify preview',
    image: 'customize-link-previews.png',
    description:
      'Create content tailored to each social channel by adjusting the image of your post.',
  },
  referrals: {
    title: 'Ambassadors',
    image: 'referrals.png',
    description: `Give your employees a simple way to amplify your brand's reach by sharing curated content across their social networks.`,
  },
  stories: {
    title: 'Articles',
    image: 'stories.png',
    icon: 'stories.svg',
    description:
      'Create blog posts, news articles, whitepapers, press releases, and more with your team and publish directly to your website.',
    testId: 'entitlement-content-stories-teaser-title',
  },
  'content-types': {
    title: 'Content types',
    image: 'stories.png',
    icon: 'stories.svg',
    description:
      'Create, collaborate and plan landing pages, newsletters, videos, webinars, podcasts, whitepapers, blogs and social media posts with your team—all in one calendar!',
  },
  'insights-socials': {
    title: 'Social Media Analytics',
    image: 'insights-socials.png',
    icon: 'chart-trend.svg',
    description:
      'Create social posts that resonate with your audience by having the right reports in place.',
  },
  'insights-referrals': {
    title: 'Insights Referrals',
    image: 'insights-referrals.png',
    icon: 'chart-trend.svg',
    description:
      'From ideation to publication, get a clear overview of your content and social media planning.',
  },
  'multi-language': {
    title: 'Multi-languages',
    image: 'multi-language.png',
    description: 'Optimize your content for multiple languages and expand your potential audience.',
  },
  'insights-campaigns': {
    title: 'Campaign Analytics',
    image: 'insights-campaigns.png',
    icon: 'chart-trend.svg',
    description:
      'Monitor and grow the performance of multiple pieces of content from the same campaign.',
  },
  'recommendations-board': {
    title: 'Content Audit',
    image: 'content-audit.png',
    // icon: 'chart-trend.svg',
    description: (
      <div>
        <h3 className="m-0 mb-4 text-lg">
          Apply data driven expert suggestions to scale your traffic
        </h3>
        <h2 className="m-0 mb-2 text-lg font-bold">Grow faster with expert SEO suggestions</h2>
        <ul className="m-0 list-none p-0">
          <li>
            <Icon icon="fa:check" className="mr-2 text-sm text-shamrock" />
            Discover high ranking keywords to gain more traffic faster
          </li>
          <li>
            <Icon icon="fa:check" className="mr-2 text-sm text-shamrock" />
            Make existing content perform better
          </li>
          <li>
            <Icon icon="fa:check" className="mr-2 text-sm text-shamrock" />
            Apply expert suggestions with a click
          </li>
        </ul>
      </div>
    ),
  },
};

const DEFAULT_ICON_SIZE = 22;

type EntitlementTeaserProps = {
  children: JSX.Element;
  entitlement: string;
  variant?: 'page' | 'section' | 'inline-overlay' | 'sidebar';
  className?: string;
  isRefreshPageAfterPurchase?: boolean;
  customAnalyticsObject?: object;
  customTitle?: string;
  customIcon?: string;
};

function EntitlementTeaser({
  children,
  entitlement,
  variant = 'page',
  className = null,
  isRefreshPageAfterPurchase = undefined,
  customAnalyticsObject = null,
  customTitle = null,
  customIcon = null,
}: EntitlementTeaserProps): JSX.Element {
  const getEntitlement = useEntitlements();
  const data = ENTITLEMENTS_DATA[entitlement];
  const isEntitled = getEntitlement(entitlement);
  const {
    data: { upgradePathForEntitlement } = { upgradePathForEntitlement: null },
    loading,
    error,
  } = useUpgradePathForEntitlement(entitlement);
  const stripePriceObject = getStripePriceObject();
  const isNoUpgradePathError = error?.graphQLErrors?.some((e) =>
    ['NO_UPGRADE_PATH', 'ARCHIVED_PLAN'].includes(e.extensions.code),
  );

  function getStripePriceObject() {
    if (!upgradePathForEntitlement) {
      return null;
    }

    if (upgradePathForEntitlement.prices.length === 1) {
      return upgradePathForEntitlement.prices[0];
    }

    return (
      upgradePathForEntitlement.prices.find((price: Price) => price.primary) ||
      upgradePathForEntitlement.prices[0]
    );
  }

  /*
   * Get all entitlements with a value such as "channels-socials-count"
   */
  function getEntitlements(): Entitlement[] {
    return [
      ...new Set(
        upgradePathForEntitlement.prices
          .map((price: Price): Entitlement[] => JSON.parse(price.entitlements))
          .flat() as Entitlement[],
      ),
    ];
  }

  useEffect(() => {
    const bodyClassNames = ['entitlement-teaser-page', `entitlement-teaser-page--${entitlement}`];

    if (variant === 'page' && !isEntitled) {
      document.querySelector('body').classList.add(...bodyClassNames);
    }

    return () => {
      if (variant === 'page') {
        document.querySelector('body').classList.remove(...bodyClassNames);
      }
    };
  }, []);

  useEffect(() => {
    if (!isEntitled) {
      analyticsTrack('Feature Teaser Viewed', { entitlement, variant, ...customAnalyticsObject });
    }
  }, []);

  // Render
  if (isEntitled) {
    return children;
  }

  if (loading && !upgradePathForEntitlement && !isNoUpgradePathError) {
    return null;
  }

  return (
    <div className={classNames(className, `entitlement-teaser entitlement-teaser--${variant}`)}>
      <div className="entitlement-teaser__content">
        <div className="entitlement-teaser__main">
          <div className="entitlement-teaser__header flex items-center gap-x-2.5">
            <h1 className="entitlement-teaser__title text-bolder mt-0" data-testid={data.testId}>
              {customIcon ? (
                <em
                  className={`icon icon-${customIcon} mr-2`}
                  aria-hidden="true"
                  style={{ fontSize: data.iconSize || DEFAULT_ICON_SIZE }}
                />
              ) : (
                data.icon && (
                  <img
                    className="entitlement-teaser__icon mr-2 inline-block"
                    aria-hidden="true"
                    src={StoryChief.asset(`images/components/entitlements/icons/${data.icon}`)}
                    alt=""
                    height={data.iconSize || DEFAULT_ICON_SIZE}
                    width={data.iconSize || DEFAULT_ICON_SIZE}
                  />
                )
              )}
              {customTitle || data.title}
            </h1>
          </div>

          <div className="entitlement-teaser__description">{data.description}</div>

          {data.image && (
            <img
              className="entitlement-teaser__img img-responsive"
              src={StoryChief.asset(`images/components/entitlements/${data.image}`)}
              alt=""
            />
          )}
        </div>

        <div className="entitlement-teaser__aside">
          {upgradePathForEntitlement ? (
            <PricingCard
              amount={upgradePathForEntitlement.amount}
              stripePrice={stripePriceObject.stripe_price}
              payload={upgradePathForEntitlement.payload}
              primaryEntitlement={entitlement}
              entitlements={getEntitlements()}
              isRefreshPageAfterPurchase={isRefreshPageAfterPurchase}
            />
          ) : (
            <PricingCardPlaceholder entitlement={entitlement} />
          )}
        </div>
      </div>
    </div>
  );
}

export default EntitlementTeaser;
