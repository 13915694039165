import classNames from 'classnames';
import PricingCardTable from '@/pricing/components/PricingTable/PricingTable';
import Button from '@/storychief/components/Button';
import useModal from '@/modals/hooks/useModal';
import omitGraphQlProps from '@/storychief/utils/omitGraphQlProps';
import useAccount from '@/account/hooks/useAccount';
import StoryChief from '@/storychief';
import analyticsTrack from '@/storychief/utils/analyticsTrack';
import {
  PRIMARY_MANAGEABLE_STRIPE_PRICES,
  STRIPE_PRICES,
  STRIPE_PRICES_UNION,
} from '@/types/stripe';
import useEntitlements from '@/entitlements/hooks/useEntitlements';
import { Entitlement, EntitlementNumber } from '@/types/entitlement';

type PricingCardType = {
  primaryEntitlement?: string;
  amount?: number;
  stripePrice: STRIPE_PRICES_UNION;
  entitlements: Entitlement[];
  isRefreshPageAfterPurchase?: boolean;
  payload?: object;
};

function PricingCard({
  primaryEntitlement = null,
  amount = null,
  stripePrice,
  isRefreshPageAfterPurchase = false,
  entitlements,
  payload = null,
}: PricingCardType) {
  const modal = useModal('CheckoutModal');
  const account = useAccount();
  const getEntitlement = useEntitlements();

  function handleOnUpgrade() {
    analyticsTrack('Upgrade Intent', {
      entitlement: primaryEntitlement,
      upgradeIntentType: 'self-service',
    });

    modal.show({
      props: {
        order: omitGraphQlProps(payload),
        // When true, only the addon will be shown in the upgrade modal
        isUpgrade: !PRIMARY_MANAGEABLE_STRIPE_PRICES.includes(stripePrice),
        isRefreshPageAfterPurchase,
      },
    });
  }

  function handleOnContactUs() {
    analyticsTrack('Upgrade Intent', {
      entitlement: primaryEntitlement,
      upgradeIntentType: 'contact',
    });

    StoryChief.startIntercomMessage();
  }

  function renderButton() {
    if (account?.subscription?.can?.update) {
      return payload ? <Button onClick={handleOnUpgrade}>Upgrade</Button> : null;
    }

    return <Button onClick={handleOnContactUs}>Contact us</Button>;
  }

  function getName() {
    switch (stripePrice) {
      case STRIPE_PRICES.STRIPE_PLAN_SMALL_BUSINESS_SOCIAL_QUARTERLY_092024:
      case STRIPE_PRICES.STRIPE_PLAN_SMALL_BUSINESS_SOCIAL_YEARLY_092024:
        return 'Small Business Socials';
      case STRIPE_PRICES.STRIPE_PLAN_SMALL_BUSINESS_SUITE_QUARTERLY_092024:
      case STRIPE_PRICES.STRIPE_PLAN_SMALL_BUSINESS_SUITE_YEARLY_092024:
        return 'Small Business Suite';
      case STRIPE_PRICES.STRIPE_PLAN_ADDON_POWERMODE_MONTHLY_EXPERIMENTAL_092024:
      case STRIPE_PRICES.STRIPE_PLAN_ADDON_POWERMODE_QUARTERLY_072024:
      case STRIPE_PRICES.STRIPE_PLAN_ADDON_POWERMODE_YEARLY_072024:
        return 'PowerMode';
      default:
        return '';
    }
  }

  function getEntitlements(): Entitlement[] {
    return entitlements
      .filter((entitlement) => 'value' in entitlement)
      .map((entitlement: EntitlementNumber): EntitlementNumber => {
        const value = getEntitlement(entitlement.key);

        return { ...entitlement, value: value || entitlement.value } as EntitlementNumber;
      });
  }

  return (
    <div
      className={classNames('sc-pricing-card space-y-2', {
        'sc-pricing-card--with-price': !!amount,
      })}
    >
      <div className="sc-pricing-card-header">
        <div className="sc-pricing-card-header__main flex items-center">
          <h3 className="sc-pricing-card__name">{getName()}</h3>
        </div>
        <div className="sc-pricing-card-header__cta">{renderButton()}</div>
      </div>
      {entitlements.length > 0 && (
        <div className="sc-pricing-card__body">
          <PricingCardTable entitlements={getEntitlements()} />
        </div>
      )}
    </div>
  );
}

export default PricingCard;
